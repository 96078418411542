import { MIXPANEL_COOKIE_NAME } from '@/constants/mixpanelCookie';
import { OptionalWhitepaperSlug } from '@/modules/Whitepapers/whitepapers';
import {
  JobGrade,
  JobSearchGrade,
  JobSearchSpeciality,
  JobSearchWebCriteria,
  JobSpeciality,
  MinPayRateUnit,
} from '@/types/jobs';
import { MixpanelCookieData, MixpanelCookieSchema } from '@/types/mixpanelCookie';
import { getCookie } from 'cookies-next';
import { useCallback } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';

import { appClientConfig } from './appClientConfig';
import { RedirectionPreAction } from './urlHelper';

const readMixpanelCookie = (): MixpanelCookieData | undefined => {
  const mixpanelCookieDataString = getCookie(MIXPANEL_COOKIE_NAME);

  if (!mixpanelCookieDataString || typeof mixpanelCookieDataString !== 'string') {
    return undefined;
  }

  try {
    const mixpanelCookieObject = JSON.parse(mixpanelCookieDataString);
    const mixpanelCookieData = MixpanelCookieSchema.validateSync(mixpanelCookieObject);

    return mixpanelCookieData;
  } catch {
    return undefined;
  }
};

export const useAnalytics = () => {
  const mixpanel = useMixpanel();

  return useCallback(
    <TEventKey extends MixpanelEventName>(
      eventName: TEventKey,
      eventDetails: MixpanelEvents[TEventKey],
    ) => {
      const mixpanelDeviceIdCookie = readMixpanelCookie();

      if (mixpanel?.config?.token) {
        //Copying to new object to avoid the passed object modification
        mixpanel.track(
          eventName,
          Object.assign(
            {},
            { product: 'Medrecruit' },
            mixpanelDeviceIdCookie ? { $device_id: mixpanelDeviceIdCookie.deviceId } : {},
            eventDetails,
          ),
        );
      } else {
        console.error(
          `token is missing in mixpanel. and the token should be ${appClientConfig.mixPanelToken}`,
        );
      }
    },
    [mixpanel],
  );
};

export interface JobSearchAnalyticsProperties extends JobSearchWebCriteria {
  autocompleteLocationSelectedType: 'specific' | 'scheduler';
  autocompleteLocationRadius: string | 'default' | null;
  payRateVisible?: boolean;
  jobSearchSimple: boolean;
}

export interface JobResultsAnalyticsProperties extends JobSearchWebCriteria {
  count: number;
  regCTAJobResultsView: boolean; // true if a registration CTA were shown in the results, false otherwise
}

export type ShareType = 'Facebook' | 'Email' | 'Link';

export type ShareJobTracking = {
  'Share Origin': 'Job Card' | 'Job Detail';
  jobNumber: string;
};

export type ShareArticleTracking = {
  'Share Origin': 'Articles';
  'Article Id': number;
  'Article Title': string;
};

export type DashboardLoadMetrics = {
  hasUploadedCv: boolean;
  hasReferees: boolean;
  hasAnsweredMotivators: boolean;
};

export type MixpanelEventName = keyof MixpanelEvents;

export type MixpanelEvents = {
  'Alert Created': {
    grade?: string;
    speciality?: string;
    location?: string;
    workType?: string;
    startDate?: string;
    endDate?: string;
    keywords?: string;
    name: string;
    frequency: string;
  };
  'Alert Updated': {
    grade?: string;
    speciality?: string;
    location?: string;
    workType?: string;
    startDate?: string;
    endDate?: string;
    keywords?: string;
    name: string;
    frequency: string;
  };
  'ContactUsLandingPage loaded': Record<string, never>;
  'JobsList pagination': {
    'Page number': number;
  };
  'Job Search': JobSearchAnalyticsProperties;
  'Job Search Results': JobResultsAnalyticsProperties;
  'Job Details page loaded': { jobNumber: string; open: boolean };
  'Job Saved': { jobNumber: string };
  'Job Enquire': { jobNumber: string };
  'Job Enquire page loaded': {
    jobNumber: string;
  };
  'Job Enquired success': {
    jobNumber: string;
    cvUploaded: boolean;
  };
  'Job Enquired error': {
    jobNumber: string;
    cvUploaded: boolean;
  };
  'Job Enquire page - back to job details': {
    jobNumber: string;
  };
  'Job Details': {
    jobNumber: string;
    'Details Origin'?: 'Job Card' | 'Job Card Snippet' | 'Similar Jobs';
    'Details Action'?: 'Title Link' | 'Button';
    referringJobNumber?: string;
  };
  'Share Job': ShareJobTracking & {
    'Share Type': ShareType;
  };
  'Share Article': ShareArticleTracking & {
    'Share Type': ShareType;
  };
  'Copy referral link': {
    affiliateCode: string | undefined | null;
  };
  'Click Share button': ShareJobTracking | ShareArticleTracking;
  'Article page loaded': {
    title: string;
    id: number;
    ctaPosition: string;
  };
  'Click Related Article': {
    title: string;
    id: number;
    source_title: string;
    source_id: number;
  };
  'User Verify Email Resend': Record<string, string>;
  'User Verify Email Resend Failure': Record<string, string>;
  'User Signin Error': Record<string, unknown>;
  'User Signin': Record<string, unknown>;
  // REGISTRATION EVENTS
  'user registration started': {
    // Capitalise the event name and remove other redundant events when cleaning MC-1359-new-registration-flow
    whitepaperName: OptionalWhitepaperSlug;
    preFilledFields?: string[]; // Check whether this could be made non-optional (or string[] | undefined) when cleaning MC-1359-new-registration-flow
  };
  'View registration screen': {
    previousRoute: string | null;
    preAction: RedirectionPreAction | null;
    whitepaperSlug: string | null;
    referralCode: string | null;
  };
  'User registration started - new registration flow': {
    // Remove this event when cleaning MC-1359-new-registration-flow. Switch to the user registration started event above.
    whitepaperName: OptionalWhitepaperSlug;
    preFilledFields?: string[];
  };
  'User registration step completed': { completedStep: string };
  'user registration completed': { whitepaperName: OptionalWhitepaperSlug };
  'User registration exit': Record<string, never>;
  'user registration failed': { details: any }; // Make the event name capital when cleaning MC-1359-new-registration-flow
  'user left basic info flow page': {
    first_name_entered: boolean;
    last_name_entered: boolean;
    email_entered: boolean;
    password_entered: boolean;
    phone_entered: boolean;
    agree_terms_entered: boolean;
  };
  'user registration basic info completed': {
    whitepaperName: OptionalWhitepaperSlug;
  };
  'user left simple flow page': {
    grade_entered: boolean;
    speciality_entered: boolean;
    registered_org_entered: boolean;
    country_of_training_entered: boolean;
    country_of_primary_degree_entered: boolean;
    countries_worked_in_entered: boolean;
    preferred_workLocation_entered: boolean;
    proposed_referred_entered: boolean;
    onboarding_questions_prefilled: boolean;
    grade_prefilled: boolean;
    speciality_prefilled: boolean;
    preferred_workLocation_prefilled: boolean;
  };
  'user registration simple flow completed': {
    whitepaperName: OptionalWhitepaperSlug;
    grade: JobGrade;
    speciality?: JobSpeciality;
    onboarding_questions_prefilled: boolean;
    grade_prefilled: boolean;
    speciality_prefilled: boolean;
    preferred_workLocation_prefilled: boolean;
  };
  'user registration subscriptions info completed': {
    Grade: string;
    whitepaperName: OptionalWhitepaperSlug;
  };
  // Registration events for single page registration flow
  'user left create account page': {
    first_name_entered: boolean;
    last_name_entered: boolean;
    email_entered: boolean;
    password_entered: boolean;
    phone_entered: boolean;
    agree_terms_entered: boolean;
    optin_marketing_entered: boolean;
  };
  'user loads onboarding page': Record<string, never>;
  'user left onboarding  page': {
    grade_entered: boolean;
    speciality_entered: boolean;
    registered_org_entered: boolean;
    country_of_training_entered: boolean;
    country_of_primary_degree_entered: boolean;
    countries_worked_in_entered: boolean;
    preferred_workLocation_entered: boolean;
    proposed_referred_entered: boolean;
  };
  'user completed onboarding page': Record<string, never>;
  'user viewed updated terms and conditions': Record<string, never>;
  'user declined updated terms and conditions': Record<string, never>;
  'Contact us via call': {
    phone_number: string;
  };
  'Contact us via email': {
    form_filled: boolean;
    email_link: boolean;
  };
  'Job results Sort By': {
    sort_by: string;
    logged_in: boolean;
    grade?: string;
    speciality?: string;
    work_type?: string;
    start_date?: string | null;
    end_date?: string | null;
    keywords?: string;
  };
  'Set up job alert button click': {
    logged_in: boolean;
    grade?: string;
    speciality?: string;
    work_type?: string;
    start_date?: string | null;
    end_date?: string | null;
    keywords?: string;
  };
  'Manage job alerts button click': {
    logged_in: boolean;
    grade?: string;
    speciality?: string;
    work_type?: string;
    start_date?: string | null;
    end_date?: string | null;
    keywords?: string;
  };
  'Saved job alert title click': Record<string, never>;
  'Apply Job Search Filter': {
    applied_filter:
      | {
          filter_type: 'clear';
          filter_value: 'all';
        }
      | {
          filter_type: 'location';
          filter_value: {
            location: string | undefined;
            locationName: string | undefined;
            lon: string | undefined;
            lat: string | undefined;
          };
        }
      | {
          filter_type: 'minPayRate';
          filter_value: {
            minPayRate: string | null;
            minPayRateUnit: MinPayRateUnit | undefined;
          };
        }
      | {
          filter_type:
            | 'startDate'
            | 'endDate'
            | 'grade'
            | 'speciality'
            | 'keywords'
            | 'workType'
            | 'radius';
          filter_value: string | undefined | null;
        };
    search: JobSearchWebCriteria;
  };
  'Job Search Register CTA click': {
    position: number;
    grade: JobSearchGrade;
    speciality: JobSearchSpeciality;
  };
  'Article CTA click': {
    name: string;
    ctaPosition: string;
  };
  'Whitepaper download': {
    whitepaperName: OptionalWhitepaperSlug;
  };
  'Book appointment dialog opened': Record<string, never>;
  'User booked appointment successfully': Record<string, never>;
  'Click nav Job Search': Record<string, never>;
  'Click nav Doctors': Record<string, never>;
  'Click nav Employers': Record<string, never>;
  'Click nav About Us': Record<string, never>;
  'Click nav Articles': Record<string, never>;
  'Click nav Contact Us': Record<string, never>;
  'Click nav Sign In': Record<string, never>;
  'Click nav Register': Record<string, never>;
  'Click nav Logo': Record<string, never>;
  'Motivator modal opened': Record<string, never>;
  'Motivator modal submitted': Record<string, never>;
  'Motivator modal has errored': Record<string, never>;
  'Motivator modal closed': {
    modalState: string;
  };
  'Motivator modal question answered': {
    modalState: string;
  };

  // Engaging materials
  'User opened CV upload dialog': Record<string, never>;
  'Uploaded CV': {
    firstTimeUploading: boolean;
  };
  'Updated referees': Record<string, never>;
  'Client - Action based user dashboard loaded': DashboardLoadMetrics;
  'Client - User dashboard loaded': DashboardLoadMetrics;
  'Click visit my digital profile': Record<string, never>;
  Click: {
    action: string;
    'element type': string; // TODO: create a specific type for elements that we want to be clickable at all
    'element text': string;
  };
};
