import { PATH } from '@/constants/path';
import { NavItem, NavItemId } from '@/types/navigation';

export const NAVIGATION_MAP: Record<NavItemId, NavItem> = {
  job_search_pages: {
    id: 'job_search_pages',
    label: 'Job search',
    to: PATH.JOBS,
    trackEventName: 'Click nav Job Search',
    testid: 'top-nav-job-search',
  },
  doctors_pages: {
    id: 'doctors_pages',
    label: 'Doctors',
    to: PATH.DOCTORS,
    trackEventName: 'Click nav Doctors',
    testid: 'top-nav-doctors',
  },
  employers_pages: {
    id: 'employers_pages',
    label: 'Employers',
    to: PATH.EMPLOYERS,
    trackEventName: 'Click nav Employers',
    testid: 'top-nav-employers',
  },
  about_us_pages: {
    id: 'about_us_pages',
    label: 'About us',
    to: PATH.ABOUT_US,
    trackEventName: 'Click nav About Us',
    testid: 'top-nav-about-us',
  },
  articles_pages: {
    id: 'articles_pages',
    label: 'Articles',
    to: PATH.ARTICLES,
    trackEventName: 'Click nav Articles',
    testid: 'top-nav-articles',
  },
  contact_us_pages: {
    id: 'contact_us_pages',
    label: 'Contact us',
    to: PATH.CONTACT_US,
    trackEventName: 'Click nav Contact Us',
    testid: 'top-nav-contact-us',
  },
  user_profile_pages: {
    id: 'user_profile_pages',
    label: 'Hi',
    to: PATH.DASHBOARD,
  },
};

export const USER_PROFILE_SUB_NAVIGATION_LIST: NavItem[] = [
  {
    label: 'Profile dashboard',
    to: PATH.DASHBOARD,
    testid: 'profile-dashboard',
  },
  {
    label: 'Job alerts',
    to: PATH.JOBS_ALERTS,
    testid: 'job-alerts',
  },
  {
    label: 'Saved jobs',
    to: PATH.SAVED_JOBS,
    testid: 'saved-jobs',
  },
  {
    label: 'Job Enquiries',
    to: PATH.JOB_ENQUIRIES,
    testid: 'job-enquiries',
  },
  {
    label: 'Account settings',
    to: PATH.ACCOUNT_SETTINGS,
    testid: 'account-settings',
  },
  {
    label: 'Logout',
    to: PATH.LOGOUT,
    testid: 'profile-logout',
  },
];
