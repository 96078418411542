import { FacebookPixel } from '@/components/FacebookPixel';
import { GoogleTagManager } from '@/components/GoogleTagManager';
import { NewrelicMonitoring } from '@/components/NewrelicMonitoring';
import { FeatureFlagContextProvider } from '@/modules/Context/FeatureFlagContextProvider';
import { NavigationContextProvider } from '@/modules/Context/NavigationContextProvider';
import { appClientConfig } from '@/utils/appClientConfig';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { DefaultSeo } from 'next-seo';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import NextNprogress from 'nextjs-progressbar';
import React from 'react';
import theme from 'styles/theme';

import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';

import { FeedbackContextProvider } from 'modules/Context/FeedbackContextProvider';
import ErrorBoundary from 'modules/ErrorBoundary';
import { ProductAnalytics } from 'modules/ProductAnalytics/ProductAnalytics';

const isLive = appClientConfig.isLive;

const generateCanonicalUrl = () => {
  const { asPath } = useRouter();
  // Returning the path alone, removing query params
  return asPath.split('?')[0];
};

const description = 'Medrecruit is a medical recruitment agency offering locum and permanent...';
const siteTitle = 'Medrecruit: Locum and Permanent Doctor Jobs';

/**
 * To load global CSS on all pages
 * See https://nextjs.org/docs/advanced-features/custom-app
 */
export default function App({ Component, pageProps }: AppProps) {
  return (
    <ProductAnalytics>
      <StylesProvider key={1}>
        <>
          <Head>
            <meta name="description" content={description} />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>{siteTitle}</title>
          </Head>
          <DefaultSeo
            openGraph={{
              site_name: 'Medrecruit',
            }}
            canonical={generateCanonicalUrl()}
            dangerouslySetAllPagesToNoFollow={!isLive}
            dangerouslySetAllPagesToNoIndex={!isLive}
          />
          <FeatureFlagContextProvider>
            <NavigationContextProvider>
              <ThemeProvider theme={theme}>
                <NextNprogress
                  color={theme.palette.primary.main}
                  startPosition={0.3}
                  height="3"
                  options={{ easing: 'ease', speed: 300 }}
                />
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <FeedbackContextProvider>
                  <ErrorBoundary>
                    <UserProvider>
                      <Component {...pageProps} />
                    </UserProvider>
                  </ErrorBoundary>

                  {isLive && <GoogleTagManager />}
                  {isLive && <FacebookPixel />}
                  {isLive && (
                    <Script
                      id="inlinks-script"
                      type="text/javascript"
                      src="https://jscloud.net/x/35659/inlinks.js"
                      strategy="afterInteractive"
                    />
                  )}
                  <NewrelicMonitoring />
                </FeedbackContextProvider>
              </ThemeProvider>
            </NavigationContextProvider>
          </FeatureFlagContextProvider>
        </>
      </StylesProvider>
    </ProductAnalytics>
  );
}
