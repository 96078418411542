import { appClientConfig } from '@/app/utils/appClientConfig';
import { MixpanelProvider } from 'react-mixpanel-browser';

export const ProductAnalytics = ({ children }: { children: React.ReactNode }) => {
  const enableLocalDebug = !appClientConfig.isLive;

  return (
    <MixpanelProvider
      token={appClientConfig.mixPanelToken ?? '45df7f84ca9209ad4155130142cd9c2c'}
      config={{ debug: enableLocalDebug, cookie_domain: appClientConfig.webDomain }}
    >
      {children}
    </MixpanelProvider>
  );
};
