import React from 'react';

import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

export enum FeedBackType {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

interface FeedBackProps {
  type: FeedBackType;
  message?: string;
  onClose: () => void;
  duration?: number;
}

export const FeedBack = ({ duration, type, message, onClose }: FeedBackProps) => {
  return (
    <Snackbar
      open={true}
      autoHideDuration={duration ?? (type === FeedBackType.SUCCESS ? 1500 : 2000)}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      data-testid="feedbackToast"
    >
      <Alert onClose={onClose} severity={type}>
        {message || type}
      </Alert>
    </Snackbar>
  );
};
