import { createTheme } from '@material-ui/core/styles';

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: [
      '"Work Sans"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          letterSpacing: '0.5px',
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        'background-color': '#ffffff',
        'box-shadow': 'none',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          'background-color': 'transparent',
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: '#278be0',
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: '16px',
      },
    },
    MuiInput: {
      root: {
        fontSize: '16px',
      },
      input: {
        color: '#033B61',
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        color: '#033B61',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      root: {
        fontSize: '18px',
        fontWeight: '500',
      },
      containedSizeLarge: {
        fontSize: '18px',
      },
    },
    MuiTypography: {
      h1: {
        letterSpacing: '0.64px',
      },
      h2: {
        letterSpacing: '0.64px',
      },
      h3: {
        fontWeight: 700,
        fontSize: '42px',
        lineHeight: '50px',
        letterSpacing: '0.64px',
        '@media (max-width: 600px)': {
          fontSize: '24px',
          lineHeight: '34px',
        },
      },
      h4: {
        fontWeight: 700,
        fontSize: '34px',
        lineHeight: '44px',
        letterSpacing: '0.64px',
        '@media (max-width: 600px)': {
          fontSize: '24px',
          lineHeight: '34px',
        },
      },
      h5: {
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '34px',
        letterSpacing: '0.64px',
      },
      h6: {
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '0.64px',
      },
      subtitle1: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '0.5px',
      },
      caption: {
        fontSize: '16px',
        lineHeight: '20px',
      },
      body1: {
        fontSize: '16px',
        lineHeight: '24px',
      },
      body2: {
        fontSize: '14px',
        lineHeight: '20px',
      },
      subtitle2: {
        fontSize: '14px',
        lineHeight: '24px',
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: '#ffffff',
      },
      toolbarBtnSelected: {
        color: '#ffffff',
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '16px',
        lineHeight: '24px',
        color: '#033b61',
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: '8px',
      },
    },
  },
  palette: {
    primary: {
      light: '#8FD4FC',
      main: '#55ADEE',
      dark: '#137FCE',
    },
    secondary: {
      light: '#0E6DB2',
      main: '#033B61',
      dark: '#032E4B',
    },
    error: {
      main: '#B00020',
    },
    background: {
      default: '#ffffff',
    },
    text: {
      primary: '#595959',
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
});

export default theme;
