import cx from 'classnames';
import Link from 'next/link';
import React from 'react';

import styles from './Logo.module.scss';
import MedrecruitFullSvg from './assets/medrecruit-full.svg';
import MedrecruitShortSvg from './assets/medrecruit-short.svg';

type LogoType = 'medrecruit-full' | 'medrecruit-short';

export type LogoProps = {
  className?: string;
  onClick?: () => void;
  type?: LogoType;
};

const altText = 'Medrecruit. A Medworld partner.';

const Logo = ({ className, onClick, type = 'medrecruit-full' }: LogoProps) => (
  <Link prefetch={false} href="/" passHref>
    <a onClick={onClick} className={cx(styles.container, className)}>
      {type === 'medrecruit-full' && (
        <MedrecruitFullSvg width="100%" height="100%" viewBox="0 0 182 42" aria-label={altText} />
      )}
      {type === 'medrecruit-short' && (
        <MedrecruitShortSvg width="100%" height="100%" viewBox="0 0 28 28" aria-label={altText} />
      )}
    </a>
  </Link>
);

export default Logo;
