import { PATH } from '@/app/constants/path';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React from 'react';

import Footer from 'modules/Footer';
import LandingHeader from 'modules/LandingHeader';

import styles from './ErrorPage.module.scss';

const CookieConsentPrompt = dynamic(() => import('@/app/components/CookieConsentPrompt'));

type ErrorPageProps = {
  errorTitle?: string;
  children?: React.ReactNode;
};

const ErrorPage = ({ errorTitle = 'Oops! Something went wrong.', children }: ErrorPageProps) => (
  <div data-testid="error-page" className={styles.errorPage}>
    <LandingHeader />
    <div className={styles.container}>
      <h3 className={styles.title}>{errorTitle}</h3>
      <div>
        {!children && (
          <div className={styles.subTitle}>
            <p>
              Uh oh, the page is not working at the moment. Please try again later or{' '}
              <Link prefetch={false} href={PATH.CONTACT_US} as={PATH.CONTACT_US}>
                <a>contact us</a>
              </Link>
            </p>
          </div>
        )}
        {children}
      </div>
    </div>
    <Footer />
    <CookieConsentPrompt />
  </div>
);

export default ErrorPage;
