import Script from 'next/script';
import React from 'react';

export const FacebookPixel = () => {
  // TODO: this needs to be wrapped in a Script component in a way that works
  return (
    <Script
      id="fb-pixel"
      strategy="lazyOnload"
      dangerouslySetInnerHTML={{
        __html: `
        !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '519127291606960'); fbq('track', 'PageView');
               `,
      }}
    />
  );
};
