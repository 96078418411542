import type { DeviceConfig } from '@/modules/Footer/utils/screenFooterItems';
import { getScreenFooterItems } from '@/modules/Footer/utils/screenFooterItems';
import { NavItem, NavItemId } from '@/types/navigation';

export const getSubFooterItems = (navId: NavItemId | undefined, navItems: NavItem[]): NavItem[] => {
  const isAllowedNavId = navId === 'doctors_pages' || navId === 'about_us_pages';

  if (isAllowedNavId && navItems?.length) {
    const navItem = navItems.find(item => item.id === navId);
    return navItem?.subItems ?? [];
  }
  return [];
};

export const getScreenMedia = (
  cmsNavItems: NavItem[],
  isMobile: boolean,
  isTablet: boolean,
): DeviceConfig => {
  const items = getScreenFooterItems(cmsNavItems);
  if (!isMobile && !isTablet) {
    return items.desktop;
  }
  return isMobile ? items.mobile : items.tablet;
};
