import { getSignInUrlClientSide } from '@/app/utils/urlHelper';
import Dialog from '@/components/Dialog';
import { PATH } from 'constants/path';
import Link from 'next/link';
import React from 'react';

import styles from './style.module.scss';

export const PromptSignInDialog: React.FC = () => {
  const signInUrl = getSignInUrlClientSide();
  return (
    <Dialog title={'You are not signed in, please...'} show={true}>
      <div className={styles.container}>
        <Link prefetch={false} href={PATH.REGISTER} as={PATH.REGISTER}>
          <a>Register</a>
        </Link>
        or{' '}
        <Link prefetch={false} href={signInUrl} as={signInUrl}>
          <a>Sign in</a>
        </Link>
      </div>
    </Dialog>
  );
};
