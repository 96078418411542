import { NavigationContext } from '@/modules/Context/NavigationContextProvider';
import NavigationItem from '@/modules/LandingHeader/components/NavigationItem/NavigationItem';
import { USER_PROFILE_SUB_NAVIGATION_LIST } from '@/modules/LandingHeader/config';
import classnames from 'classnames';
import React, { useContext } from 'react';

import styles from './Navigation.module.scss';

export type NavigationProps = {
  onNavItemClick?: () => void;
  isUserLoggedIn: boolean;
  userName: string;
  actionButtons?: React.ReactElement;
};

export const Navigation = ({
  onNavItemClick,
  isUserLoggedIn,
  userName,
  actionButtons,
}: NavigationProps) => {
  const navigationList = useContext(NavigationContext);

  if (navigationList.length === 0) {
    return <></>;
  }

  return (
    <>
      <nav aria-label="Top navigation" className={styles.nav}>
        <ul className={styles.list}>
          {navigationList.map(item => (
            <li className={styles.listItem} key={item.label}>
              <NavigationItem
                label={item.label}
                trackEventName={item.trackEventName}
                to={item.to}
                onNavItemClick={onNavItemClick}
                subItems={item.subItems}
                id={item.id}
              />
            </li>
          ))}
        </ul>
      </nav>

      {isUserLoggedIn && (
        <nav aria-label="User navigation" className={classnames(styles.nav, styles.aside)}>
          <ul className={styles.list}>
            <li className={styles.listItem}>
              <NavigationItem
                label={`Hi ${userName}`}
                to={''}
                onNavItemClick={onNavItemClick}
                subItems={USER_PROFILE_SUB_NAVIGATION_LIST}
                id={'user_profile_pages'}
                isAside={true}
              />
            </li>
          </ul>
        </nav>
      )}
      {actionButtons}
    </>
  );
};

export default Navigation;
