import { NavItem } from '@/types/navigation';
import classnames from 'classnames';
import Link from 'next/link';
import React from 'react';

import styles from './FooterItem.module.scss';

export type FooterItemProps = {
  name: string;
  href: string;
  subItems?: NavItem[];
  passHref?: boolean;
};

const FooterItem = ({ name, href, subItems, passHref }: FooterItemProps) => (
  <>
    <Link prefetch={false} passHref={passHref} href={href} as={href}>
      <a className={styles.link}>{name}</a>
    </Link>
    {subItems
      ? subItems.map(item => (
          <Link key={item.label} prefetch={false} href={item.to} as={item.to} passHref>
            <a className={classnames(styles.link, styles.subLink)}>{item.label}</a>
          </Link>
        ))
      : null}
  </>
);

export default FooterItem;
