import Script from 'next/script';
import React from 'react';

/**
 * Google Tag Manager lets marketing add 'tags' to pages on the site using config.
 * This is used to bring in Taboola, CrazyEgg, Clarity.ms, doubleclick.net etc, and also makes the global ga variable
 * available.
 * See https://developers.google.com/tag-platform/tag-manager/web
 */
export const GoogleTagManager = () => {
  // TODO: this needs to be wrapped in a Script component in a way that works
  // TODO make the id be a config param
  return (
    <Script
      id="gtm"
      strategy="lazyOnload"
      dangerouslySetInnerHTML={{
        __html: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-PCCVRR3');
        `,
      }}
    />
  );
};

export const GoogleTagManagerNoScript = () => {
  return (
    <noscript>
      <iframe
        title="Google Tag Manager"
        src="https://www.googletagmanager.com/ns.html?id=GTM-PCCVRR3"
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      ></iframe>
    </noscript>
  );
};
