import { User } from '@/types/user';
import { useUser } from '@auth0/nextjs-auth0/client';

export type UserContextData = {
  user: User | null;
  isLoading: boolean;
  error?: Error;
  checkSession: () => Promise<void>;
};
/**
 * Used to retrieve the user information at the client side.
 * Map the Auth0 user (which doesn't have firstName/lastName properties,
 * just “name”) to our user type having given_name and family_name to be
 * consistent everywhere.
 *
 * @returns UserContextData
 */
export const useAuth0User = (): UserContextData => {
  const { user: auth0User, isLoading, error, checkSession } = useUser();

  if (!auth0User) {
    return { user: null, isLoading, error, checkSession };
  }
  if (!auth0User.email) {
    throw new Error('No email address configured in auth0 for the user');
  }
  const user: User = {
    email: auth0User.email,
    given_name: auth0User.given_name as string,
    family_name: auth0User.family_name as string,
    mr_user_id: auth0User['mr_user_id'] as string,
    email_verified: auth0User.email_verified as boolean,
  };
  globalThis?.dataLayer?.push({
    user_id: user.mr_user_id,
  });
  return { user, isLoading, error, checkSession };
};
