/**
 * Utility for building links optional query parameters
 * @param url the base URL to send the user to. Must be a value from PATH.
 * @param params the additional properties to add to the link. These will be URI encoded inside this method so pass them in raw
 */
import { PATH } from '@/constants/path';

export function getUrlWithQueryParameters(
  url: (typeof PATH)[keyof typeof PATH],
  params: Record<string, string | null | undefined> = {},
) {
  const additionalParams = new URLSearchParams();
  const valuedParams = Object.entries(params).filter(entry => entry && !!entry[1]);
  if (valuedParams.length === 0) {
    return url;
  }
  valuedParams.forEach(entry => {
    additionalParams.append(entry[0], entry[1] || '');
  });
  return `${url}?${additionalParams.toString()}`;
}
