export const PATH = {
  HOME: '/',
  LOGIN: '/signin',
  LOGOUT: '/api/logout',
  REGISTER: '/register',
  JOBS: '/jobs',
  JOBSLIST: '/jobs/list',
  JOBSLISTSEO: '/jobs/listing',
  DASHBOARD: '/user/dashboard',
  ACCOUNT_SETTINGS: '/user/account',
  COMMUNICATIONS: '/user/account/communications',
  JOB_ENQUIRIES: '/user/job-enquiries',
  SAVED_JOBS: '/user/saved-jobs',
  REFEREES: '/user/referees',
  JOBS_ALERTS: '/user/job-alerts',
  ONBOARDING: '/user/onboarding',
  JOB_ENQUIRE: '/enquire',
  JOB_ENQUIRY_SUCCESS: '/enquired-success',
  ARTICLES: '/articles',
  ARTICLE_CATEGORY: '/articles/category',
  ARTICLE_TAG: '/articles/tag',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  ACCEPT_TERMS_AND_CONDITIONS: '/terms-and-conditions/accept',
  DOCTORS: '/doctors',
  DOCTORS_SPECIALITIES_IN_DEMAND: '/doctors/specialities-in-demand',
  DOCTORS_RESOURCES: '/doctors/resources',
  ABOUT_US: '/about-us',
  B_CORP: '/about-us/b-corp',
  EMPLOYERS: '/employers',
  CONTACT_US: '/contact-us',
  PRIVACY_POLICY: '/privacy-policy',
  UNIVERSAL_LOGIN: '/api/auth/login',
  UNIVERSAL_LOGIN_ERROR: '/login-error',
  UNIVERSAL_LOGIN_RESEND_VERIFY_EMAIL: '/login-error/email-verification-required',
} as const;
