import { ApiError } from '@/services/serviceApi';

const stringifyError = err => {
  const plainObject = {};
  Object.getOwnPropertyNames(err).forEach(function (key) {
    plainObject[key] = err[key];
  });
  return JSON.stringify(plainObject, null, '\t');
};

/**
 * Format additional data about an Error in a way that Newrelic will accept.
 * @param error the Error itself
 * @param info the info
 * @return the data from info, in a format suitable for sending to newrelic.noticeError()
 */
function getFormattedInfo(
  error: Error | ApiError,
  info: { [p: string]: string | number | Record<string, unknown> | null } = {},
): {
  [key: string]: string | number | boolean;
} {
  let additionalAttributes = {
    MR_cause: (error.cause && error.cause['message']) || '',
  };
  if ('getAdditionalErrorInfo' in error) {
    additionalAttributes = {
      ...additionalAttributes,
      ...error.getAdditionalErrorInfo(),
    };
  }
  //Identifying if any objects
  const objectKeys = Object.keys(info).filter(i => typeof info[i] === 'object');
  //Serialising if any objects to string
  const serialisedInfo = objectKeys.map(key => {
    return { [key]: stringifyError(info[key]) };
  });
  //Return as single array
  const formattedInfo = serialisedInfo.reduce((data, record) => {
    return { ...data, ...record };
  }, {});
  // Include the info, overriding the value for object fields, plus the additional fields
  return { ...info, ...formattedInfo, ...additionalAttributes };
}

export default getFormattedInfo;
