import {
  RedirectionPreAction,
  getRegisterUrlServerSide,
  getSignInUrlServerSide,
} from '@/utils/urlHelper';
import { useAnalytics } from '@/utils/useAnalytics';
import Link from 'next/link';
import React from 'react';

import { Button } from '@material-ui/core';

import styles from './ActionButtons.module.scss';

export type ActionButtonsProps = {
  onRegisterClick?: () => void;
  onSignInClick?: () => void;
  redirectUrl?: string;
  preAction?: RedirectionPreAction;
};

export const ActionButtons = ({
  onRegisterClick,
  onSignInClick,
  redirectUrl,
  preAction,
}: ActionButtonsProps) => {
  const track = useAnalytics();

  const signInUrl = getSignInUrlServerSide(redirectUrl, preAction);
  const registerUrl = getRegisterUrlServerSide({ redirect_url: redirectUrl, action: preAction });

  return (
    <div className={styles.actions}>
      <Link prefetch={false} href={signInUrl} as={signInUrl} passHref>
        <Button
          color="primary"
          data-testid="sign-in-button"
          className={styles.signInButton}
          onClick={() => {
            track('Click nav Sign In', {});
            onSignInClick?.();
          }}
        >
          SIGN IN
        </Button>
      </Link>
      <Link prefetch={false} href={registerUrl} as={registerUrl} passHref>
        <Button
          variant="contained"
          color="primary"
          data-testid="register-button"
          className={styles.registerButton}
          onClick={() => {
            track('Click nav Register', {});
            onRegisterClick?.();
          }}
        >
          REGISTER
        </Button>
      </Link>
    </div>
  );
};

export default ActionButtons;
