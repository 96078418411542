import { FeatureFlags } from '@/types/featureFlags';
import { CookieValueTypes } from 'cookies-next';

/**
 * Utility functions common to server and client side split functions
 */

export const parseSplitAttributes = (cookie: CookieValueTypes): Record<string, FeatureFlags[]> => {
  const getParseValue = (value): FeatureFlags[] => {
    try {
      return JSON.parse(value);
    } catch (err) {
      return [];
    }
  };

  const modes = getParseValue(cookie);

  const testModes: FeatureFlags[] = modes.filter(mode =>
    Object.values(FeatureFlags).includes(mode),
  );

  return {
    testModes,
  };
};
