import {
  MakeRequestError,
  MakeRequestFailureResponse,
  MakeRequestSuccessResponse,
} from '@/types/useRequestTypes';
import Router from 'next/router';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { WithChildren } from 'types/withChildren';

import { FeedBack, FeedBackType } from 'components/FeedBack';
import { PromptSignInDialog } from 'components/PromptSignInDialog';

interface FeedbackState {
  statusCode: number;
  state: FeedBackType;
  message: string;
  errorCode?: number;
  onClose?: () => void;
}

interface FeedbackContextProps {
  feedbackState: FeedbackState | null;
  setFeedbackState: (state: FeedbackState) => void;
}

export type ErrorFeedback = MakeRequestFailureResponse | { errorMessage: string };

export type SuccessFeedback = MakeRequestSuccessResponse<any> | { successMessage: string };

export const FeedbackContext = createContext<FeedbackContextProps | undefined>(undefined);

function extractSuccessState(data: SuccessFeedback): Exclude<FeedbackState, 'onClose'> {
  if ('response' in data) {
    return {
      statusCode: data.response.status,
      state: FeedBackType.SUCCESS,
      message: 'Success',
    };
  }
  return {
    statusCode: 200,
    state: FeedBackType.SUCCESS,
    message: data.successMessage,
  };
}

function extractErrorState(error: ErrorFeedback): Exclude<FeedbackState, 'onClose'> {
  const errorResponse: MakeRequestError | { errorMessage: string } =
    'error' in error ? error.error : error;
  let status = 500;
  if (errorResponse && 'statusCode' in errorResponse) {
    status = errorResponse.statusCode;
  }
  if (errorResponse && 'status' in errorResponse) {
    status = errorResponse.status;
  }

  return {
    statusCode: status,
    state: FeedBackType.ERROR,
    message:
      errorResponse && 'errorMessage' in errorResponse
        ? errorResponse.errorMessage
        : errorResponse?.message ?? 'Error',
    errorCode: errorResponse && 'errorCode' in errorResponse ? errorResponse.errorCode : undefined,
  };
}

export const useFeedBackOutlet = () => {
  const context = useContext(FeedbackContext);
  const setFeedbackState = context?.setFeedbackState;

  return useCallback(
    (data: ErrorFeedback | SuccessFeedback, onClose?: () => void) => {
      if ('error' in data || 'errorMessage' in data) {
        setFeedbackState?.({ ...extractErrorState(data), onClose });
      } else {
        setFeedbackState?.({ ...extractSuccessState(data), onClose });
      }
    },
    [setFeedbackState],
  );
};

export const FeedbackContextProvider: React.FC<WithChildren> = ({ children }) => {
  const [feedbackState, setFeedbackState] = useState<FeedbackState | null>(null);
  const context = useMemo(() => ({ feedbackState, setFeedbackState }), [feedbackState]);

  useEffect(() => {
    const listener = () => setFeedbackState(null);
    Router.events.on('routeChangeStart', listener);
    return () => Router.events.off('routeChangeStart', listener);
  }, []);

  const userIsNotLoggedIn = feedbackState?.statusCode === 401;
  const userAccountDisabled =
    feedbackState?.statusCode === 403 && feedbackState?.errorCode === 11006;
  const hasSpecialHandling = userIsNotLoggedIn || userAccountDisabled;

  return (
    <FeedbackContext.Provider value={context}>
      {children}
      {userIsNotLoggedIn && <PromptSignInDialog />}
      {userAccountDisabled && (
        <FeedBack
          type={FeedBackType.ERROR}
          message={'Account disabled - Please contact to reinstate.'}
          onClose={() => {
            feedbackState?.onClose?.();
          }}
        />
      )}
      {feedbackState?.state && !hasSpecialHandling && (
        <FeedBack
          type={feedbackState.state}
          message={feedbackState.message || feedbackState.state}
          onClose={() => {
            setFeedbackState(null);
            feedbackState?.onClose?.();
          }}
        />
      )}
    </FeedbackContext.Provider>
  );
};
