import type {
  CmsNavData,
  CmsNavDataItem,
  CmsNavItem,
  CmsNavSubItem,
  DynamicPageData,
} from '@/types/cms';
import { NavItemId } from '@/types/navigation';

import { tryToNoticeClientError } from './clientErrorInfo';

/**
 * Light-weight functions for accessing CMS content, suitable for use in the browser.
 */
export const get404Page = async (): Promise<DynamicPageData> => {
  const response = await fetch('/api/cms/404-page');
  if (response.ok) {
    return response.json();
  } else {
    const error = new Error('Failed to load 404 content from CMS');
    tryToNoticeClientError(error, {
      responseStatus: response.status,
      responseStatusText: response.statusText,
      response: await response.text(),
    });
    throw error; // TODO this should trigger the client side error boundary, but doesn't seem to
  }
};

const pageNameToNavigationId: Record<string, NavItemId> = {
  'About us': 'about_us_pages',
  Doctors: 'doctors_pages',
  Employers: 'employers_pages',
  'Contact us': 'contact_us_pages',
  'Job search': 'job_search_pages',
  Articles: 'articles_pages',
};

/**
 * Load the navigation menu data from the CMS
 * @return an ordered array of menu items which should be displayed
 */
export const getNavigationItems = async (): Promise<CmsNavItem[]> => {
  function getChildItems(
    attributeName: NavItemId | undefined,
    data: CmsNavData,
  ): Array<CmsNavSubItem> {
    if (attributeName === undefined) {
      return [];
    }
    return data[attributeName]?.map(({ id, title, slug }) => ({
      title: title.trim(),
      slug: slug.trim(),
    }));
  }

  const response = await fetch('/api/cms/navigation-menu').catch(e => {
    const error = new Error('Failed to load Nav Items from CMS');
    tryToNoticeClientError(error, {
      name: e.name,
      message: e.message,
    });
  });

  if (response) {
    if (response.ok) {
      const cmsData: CmsNavData = await response.json();
      return cmsData.nav_items
        .filter(item => item?.authoritative_name)
        .map((item: CmsNavDataItem) => {
          const attributeName = pageNameToNavigationId[item.authoritative_name];
          return {
            id: attributeName,
            name: item.authoritative_name,
            displayName: item.display_name,
            childItems: getChildItems(attributeName, cmsData),
          };
        });
    } else {
      const error = new Error('Failed to load Nav Items from CMS');
      tryToNoticeClientError(error, {
        responseStatus: response.status,
        responseStatusText: response.statusText,
        response: await response.text(),
      });
      return [];
    }
  } else {
    return [];
  }
};
