import Logo from '@/components/Logo';
import { PATH } from '@/constants/path';
import { NavigationContext } from '@/modules/Context/NavigationContextProvider';
import FooterItem from '@/modules/Footer/components/FooterItem/FooterItem';
import BCorpIcon from '@/modules/Footer/imgs/b-corp.svg';
import FacebookIcon from '@/modules/Footer/imgs/facebook.svg';
import InstagramIcon from '@/modules/Footer/imgs/instagram.svg';
import TwitterIcon from '@/modules/Footer/imgs/twitter.svg';
import { getScreenMedia, getSubFooterItems } from '@/modules/Footer/utils/footerHelpers';
import { APP_VERSION } from '@/utils/appVersion';
import Link from 'next/link';
import React, { useContext } from 'react';

import { useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';

import styles from './Footer.module.scss';

const SocialIcons = () => (
  <Grid item md={12} xs={12} className={styles.iconsContainer}>
    <div className={styles.socialIcons}>
      <a
        href="https://twitter.com/medrecruit"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="twitter"
        className={styles.socialIconLink}
        style={{ width: '24px' }}
      >
        <TwitterIcon
          height="20"
          width="24"
          viewBox="0 0 16 13"
          aria-hidden="true"
          focusable="false"
        />
      </a>
      <a
        href="https://www.facebook.com/Medrecruit"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="facebook"
        className={styles.socialIconLink}
      >
        <FacebookIcon
          height="20"
          width="20"
          viewBox="0 0 18 18"
          aria-hidden="true"
          focusable="false"
        />
      </a>
      <a
        href="https://www.instagram.com/__medrecruit/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="instagram"
        className={styles.socialIconLink}
      >
        <InstagramIcon
          height="20"
          width="20"
          viewBox="0 0 16 16"
          aria-hidden="true"
          focusable="false"
        />
      </a>
    </div>
  </Grid>
);

const Copyright = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className={styles.copyright}>
      <div>
        <span className={styles.noWrap}>&copy; {currentYear} Medrecruit.</span>{' '}
        <span className={styles.noWrap}>All rights reserved.</span>
      </div>
      <div className={styles.noWrap}>Version {APP_VERSION}</div>
    </div>
  );
};

const FooterItemColumns: React.FC<{ isTablet: boolean; isMobile: boolean }> = ({
  isMobile,
  isTablet,
}) => {
  const cmsNavItems = useContext(NavigationContext);
  const screen = getScreenMedia(cmsNavItems, isMobile, isTablet);
  return screen.columns.map(col => (
    <Grid item md={3} sm={6} xs={12} key={col.id} className={styles.menuItem}>
      {col.footerItemLinks.map(item => (
        <FooterItem
          key={item.id}
          name={item.name}
          href={item.href}
          passHref={item.passHref ?? false}
          subItems={getSubFooterItems(item.navId, cmsNavItems)}
        />
      ))}
    </Grid>
  ));
};

const LogoColumn: React.FC<{ isMobile: boolean; isTablet: boolean }> = ({ isMobile, isTablet }) => {
  return (
    <Grid item md={4} xs={12} className={styles.logoOuterContainer}>
      <Grid container justifyContent="center" className={styles.logoInnerContainer}>
        {!isMobile && <Logo className={styles.logoImage} />}
        <a href={PATH.B_CORP} className={styles.bcorpImageContainer}>
          <BCorpIcon width="100%" height="100%" viewBox="0 0 75 121" alt="B-Corp logo" />
        </a>
      </Grid>
      {isTablet && (
        <>
          <SocialIcons />
          <div className={styles.mobilePrivacyAndConditions}>
            <Link href={PATH.PRIVACY_POLICY} as={PATH.PRIVACY_POLICY}>
              <a>Privacy</a>
            </Link>{' '}
            |{' '}
            <Link href={PATH.TERMS_AND_CONDITIONS} as={PATH.TERMS_AND_CONDITIONS}>
              <a>Terms and conditions</a>
            </Link>
          </div>
        </>
      )}
      <Copyright />
    </Grid>
  );
};

const ItemsColumn: React.FC<{ isTablet: boolean; isMobile: boolean }> = ({
  isMobile,
  isTablet,
}) => {
  return (
    <Grid item md={8} xs={12}>
      <Grid container spacing={2}>
        <FooterItemColumns isMobile={isMobile} isTablet={isTablet} />

        {!isMobile && !isTablet && <SocialIcons />}
      </Grid>
    </Grid>
  );
};

export const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <footer className={styles.container}>
      <Grid container spacing={2}>
        <LogoColumn isTablet={isTablet} isMobile={isMobile} />
        <ItemsColumn isTablet={isTablet} isMobile={isMobile} />
      </Grid>
    </footer>
  );
};

export default Footer;
