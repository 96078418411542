import Logo from '@/components/Logo';
import { useAuth0User } from '@/modules/Context/useAuth0User';
import ActionButtons from '@/modules/LandingHeader/components/ActionButtons/ActionButtons';
import Navigation from '@/modules/LandingHeader/components/Navigation/Navigation';
import { RedirectionPreAction } from '@/utils/urlHelper';
import { useAnalytics } from '@/utils/useAnalytics';
import { useCloseOnOutsideClick } from '@/utils/useCloseOnOutsideClick';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

import { Slide, Theme, useMediaQuery } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';

import styles from './LandingHeader.module.scss';

const mobileMenuId = 'primary-menu-mobile';

export type LandingHeaderProps = {
  onRegisterClick?: () => void;
  redirectUrl?: string;
  preAction?: RedirectionPreAction;
};

export const LandingHeader = ({ onRegisterClick, redirectUrl, preAction }: LandingHeaderProps) => {
  const track = useAnalytics();

  const { user } = useAuth0User();
  const isUserLoggedIn = !!user;
  const userName = user?.given_name ?? '';

  const isShortLogo = useMediaQuery((theme: Theme) => theme.breakpoints.between('md', 1200)); // 1200 magic value is also used in CSS, make sure to update in both places
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useCloseOnOutsideClick(isOpen, setIsOpen);

  // close Mobile menu if breakpoint is changed to desktop
  useEffect(() => {
    if (!isSmallScreen) {
      setIsOpen(false);
    }
  }, [isSmallScreen]);

  const panel = (
    <div
      className={classnames(styles.panel, isSmallScreen && isOpen && styles.showPanel)}
      id={mobileMenuId}
    >
      <div className={styles.panelInner}>
        <Navigation
          onNavItemClick={() => setIsOpen(false)}
          isUserLoggedIn={isUserLoggedIn}
          userName={userName}
          actionButtons={
            !isUserLoggedIn ? (
              <ActionButtons
                preAction={preAction}
                redirectUrl={redirectUrl}
                onRegisterClick={() => {
                  onRegisterClick?.();
                  setIsOpen(false);
                }}
                onSignInClick={() => setIsOpen(false)}
              />
            ) : (
              <></>
            )
          }
        ></Navigation>
      </div>
    </div>
  );

  return (
    <>
      {isSmallScreen && <div className={classnames(styles.backdrop, isOpen && styles.visible)} />}

      <header className={styles.container} ref={containerRef}>
        <div className={styles.headerInner}>
          <Logo
            className={styles.logo}
            type={isShortLogo ? 'medrecruit-short' : 'medrecruit-full'}
            onClick={() => {
              track('Click nav Logo', {});
              setIsOpen(false);
            }}
          />

          {isSmallScreen && (
            <button
              className={styles.toggleButton}
              onClick={() => setIsOpen(!isOpen)}
              aria-label={isOpen ? 'Close menu' : 'Open menu'}
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              data-testid={'top-nav-mobile-menu-expand-button'}
            >
              {isOpen ? <CloseIcon /> : <MenuIcon />}
            </button>
          )}
        </div>

        {isSmallScreen && (
          <Slide in={isOpen} direction={'down'}>
            {panel}
          </Slide>
        )}

        {!isSmallScreen && panel}
      </header>
    </>
  );
};

export default LandingHeader;
