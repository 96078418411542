import Cookies from 'js-cookie';
import { FeatureFlags } from 'types/featureFlags';
import { v4 as uuidv4 } from 'uuid';

import { parseSplitAttributes } from './splitUtils';

/**
 * Gets or sets a cookie of a uuid for split io experiments.
 *
 * Split io needs a way to distinguish between anonymous users. It is recommended
 * that we use a unique cookie in this case.
 *
 * @returns a uuid that is saved in cookies
 */
export const getSplitBrowserUserKey = (): string => {
  const cookie = Cookies.get('split-unique-id');

  if (!cookie) {
    const splitUniqueId = uuidv4();
    Cookies.set('split-unique-id', splitUniqueId, { expires: 60, sameSite: 'lax' });
    return splitUniqueId;
  }
  Cookies.set('split-unique-id', cookie, { expires: 60, sameSite: 'lax' });
  return cookie;
};

export const getSplitBrowserAttributes = (): Record<string, FeatureFlags[]> =>
  parseSplitAttributes(Cookies.get('testModes') || '[]');
