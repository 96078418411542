import React from 'react';

import {
  DialogContent,
  DialogTitle,
  IconButton,
  Dialog as MDialog,
  DialogProps as MDialogProps,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import styles from './style.module.scss';

export interface DialogProps extends Omit<MDialogProps, 'open' | 'title'> {
  title?: JSX.Element | string | null;
  show: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
}

const Dialog = ({ title, show, onClose, children, ...rest }: DialogProps) => {
  return (
    <MDialog className={styles.container} onClose={onClose} open={show} {...rest}>
      <DialogTitle>
        {onClose && (
          <IconButton className={styles.closeIconButton} aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
        <span className={styles.title}>{title}</span>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </MDialog>
  );
};

export default Dialog;
