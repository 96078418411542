import { appClientConfig } from '@/app/utils/appClientConfig';
import { tryToNoticeClientError } from '@/utils/clientErrorInfo';
import { ErrorLogger, LocalhostFromObject, SplitFactory } from '@splitsoftware/splitio-browserjs';
import { createContext, useEffect, useState } from 'react';
import { getSplitBrowserUserKey } from 'utils/getSplitBrowserCookie';

export const FeatureFlagContext = createContext<SplitIO.IClient | undefined>(undefined);

/* 
  Note : Use this only in the case when its right to make the flag decision in the browser
  In the Server side rendering flags will be undefined which have the following side effects:
  1> CLS score of the pages will increase so as the performance rating if text changes after page load.
  2> Search engines will be indexing the text with default "off" scenario even if the flag
  is on for 100% off the user.
  Example of how to use:
  ----------------------
  export const LandingHeader = ({
    .... 
    const featureFlagClient = useContext(FeatureFlagContext);
    const treatment = featureFlagClient?.getTreatment('your-feature-flag'); // ? needed as it will never be undefined but type is <SplitIo.Iclient | undefined>
    ....
    return (
      .... 
      {treatment === 'new' ? <component1/> : component2}
    )
  })
*/

export const FeatureFlagContextProvider = ({ children }) => {
  const [featureFlagClient, setFeatureFlagClient] = useState<SplitIO.IClient | undefined>(
    undefined,
  );

  useEffect(() => {
    const initSplitFactory = () => {
      globalThis.split =
        globalThis.split ||
        SplitFactory({
          core: {
            authorizationKey: appClientConfig.split.clientKey ?? '',
            // Key for the moment is set to a unique cookie
            // as all experiments for now will assume that
            // users are not signed in.
            // Future state will use either a cookie or their user id
            key: getSplitBrowserUserKey(),
          },
          debug: ErrorLogger(),
          features: {
            // This is where any feature flag config for local dev / localhost mode goes
            // in format 'key' : 'treatment' e.g 'reporting_v2': 'on'
          },
          sync: {
            localhostMode: LocalhostFromObject(),
          },
        });
    };

    const getSplitClient = () => {
      if (!featureFlagClient) {
        const splitClient = globalThis.split.client();
        splitClient
          .ready()
          .then(() => setFeatureFlagClient(splitClient))
          .catch(e => {
            tryToNoticeClientError(e);
          });
      }
    };

    initSplitFactory();
    getSplitClient();

    return () => {
      if (featureFlagClient) {
        featureFlagClient.destroy();
        setFeatureFlagClient(undefined);
      }
    };
  }, [featureFlagClient]);
  return (
    <FeatureFlagContext.Provider value={featureFlagClient}>{children}</FeatureFlagContext.Provider>
  );
};
