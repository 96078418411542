import { NavItem } from '@/types/navigation';
import { useAnalytics } from '@/utils/useAnalytics';
import classnames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';

import { Collapse, Theme, useMediaQuery } from '@material-ui/core';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import styles from './NavigationItem.module.scss';

export type NavigationItemProps = NavItem & {
  onNavItemClick?: () => void;
  isAside?: boolean;
};

export const NavigationItem = ({
  onNavItemClick,
  label,
  to,
  trackEventName,
  subItems,
  id,
  isAside = false,
  testid,
}: NavigationItemProps) => {
  const track = useAnalytics();
  const router = useRouter();

  const [isOpen, setIsOpen] = useState(false);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isAsideOnMobile = isAside && isMobile;

  const hasSubMenu = subItems && subItems.length > 0;

  const hasCurrentInSubMenu = useMemo<boolean>(() => {
    let hasCurrent = false;
    if (router?.asPath && hasSubMenu) {
      for (const item of subItems) {
        if (!!item.to && item.to === router?.asPath) {
          hasCurrent = true;
          break;
        }
      }
    }
    return hasCurrent;
  }, [router?.asPath, hasSubMenu, subItems]);

  const isLink = !!to;
  const isCurrent = (isLink && router?.asPath === to) || hasCurrentInSubMenu;

  // keep aside item open on mobile
  useEffect(() => {
    if (isAsideOnMobile) {
      setIsOpen(isAsideOnMobile);
    }
  }, [isAsideOnMobile]);

  // close dropdowns if breakpoint changes to desktop
  useEffect(() => {
    if (!isMobile) {
      setIsOpen(false);
    }
  }, [isMobile]);

  // open dropdowns on mobile when subitems contain the current page
  useEffect(() => {
    if (isMobile && hasCurrentInSubMenu) {
      setIsOpen(true);
    }
  }, [isMobile, hasCurrentInSubMenu]);

  return (
    <div
      onMouseEnter={() => !isMobile && hasSubMenu && setIsOpen(true)}
      onMouseLeave={() => !isMobile && hasSubMenu && setIsOpen(false)}
    >
      <div className={classnames(styles.container, isOpen && styles.open, isAside && styles.aside)}>
        {isLink && (
          <Link prefetch={false} href={to} as={to} passHref>
            <a
              className={classnames(styles.inner, styles.link, isCurrent && styles.current)}
              onClick={() => {
                trackEventName && track(trackEventName, {});
                onNavItemClick?.();
              }}
              data-testid={testid}
              aria-current={isCurrent}
            >
              {label}
            </a>
          </Link>
        )}
        {!isLink && (
          <div className={styles.inner} data-testid={testid}>
            {isAside && !isMobile && <AccountCircleOutlinedIcon className={styles.icon} />}
            <span>{label}</span>
          </div>
        )}

        {hasSubMenu && !isAsideOnMobile && (
          <button
            className={styles.expandButton}
            onClick={() => setIsOpen(!isOpen)}
            aria-label={isOpen ? `Close ${label}` : `Open ${label}`}
            aria-controls={id}
            aria-haspopup="true"
            data-testid={`top-nav-${id}-expand-button`}
          >
            {isOpen ? <ExpandLess /> : <ExpandMore />}
          </button>
        )}
      </div>

      {hasSubMenu && (
        <Collapse in={isOpen}>
          <ul className={classnames(styles.submenu, isAside && styles.aside)} id={id}>
            {subItems.map(item => {
              const isCurrentSubItem = !!item.to && router?.asPath === item.to;
              return (
                <li className={styles.submenuItem} key={item.label}>
                  <Link prefetch={false} href={item.to} as={item.to} passHref>
                    <a
                      className={classnames(styles.submenuLink, isCurrentSubItem && styles.current)}
                      onClick={() => {
                        onNavItemClick?.();
                      }}
                      data-testid={item.testid}
                      aria-current={isCurrentSubItem}
                    >
                      {item.label}
                    </a>
                  </Link>
                </li>
              );
            })}
          </ul>
        </Collapse>
      )}
    </div>
  );
};

export default NavigationItem;
