import { PATH } from '@/constants/path';
import type { NavItem, NavItemId } from '@/types/navigation';

export type FooterItemLink = {
  id: string;
  navId?: NavItemId;
  name: string;
  href: string;
  passHref?: boolean;
};

export type Column = {
  id: string;
  footerItemLinks: FooterItemLink[];
};

export type DeviceConfig = {
  columns: Column[];
};

export type DeviceType = 'mobile' | 'tablet' | 'desktop';

export function getScreenFooterItems(navItems: NavItem[]): Record<DeviceType, DeviceConfig> {
  const itemLabels = new Map(navItems.map(item => [item.id, item.label]));
  return {
    mobile: {
      columns: [
        {
          id: 'mobile-col-1',
          footerItemLinks: [
            {
              id: 'mobile-item-1',
              name: itemLabels.get('job_search_pages') ?? 'Job search',
              href: PATH.JOBS,
            },
            {
              id: 'mobile-item-2',
              name: 'Specialities in demand',
              href: PATH.DOCTORS_SPECIALITIES_IN_DEMAND,
              passHref: true,
            },
            {
              id: 'mobile-item-3',
              name: itemLabels.get('contact_us_pages') ?? 'Contact us',
              href: PATH.CONTACT_US,
              passHref: true,
            },
          ],
        },
      ],
    },
    tablet: {
      columns: [
        {
          id: 'tablet-col-1',
          footerItemLinks: [
            {
              id: 'tablet-item-1',
              navId: 'employers_pages',
              name: itemLabels.get('employers_pages') ?? 'Employers',
              href: PATH.EMPLOYERS,
            },
            {
              id: 'tablet-item-2',
              navId: 'doctors_pages',
              name: itemLabels.get('doctors_pages') ?? 'Doctors',
              href: PATH.DOCTORS,
            },
          ],
        },
        {
          id: 'tablet-col-2',
          footerItemLinks: [
            {
              id: 'tablet-item-3',
              navId: 'job_search_pages',
              name: itemLabels.get('job_search_pages') ?? 'Job search',
              href: PATH.JOBS,
            },
            {
              id: 'tablet-item-4',
              navId: 'articles_pages',
              name: itemLabels.get('articles_pages') ?? 'Articles',
              href: PATH.ARTICLES,
              passHref: true,
            },
            {
              id: 'tablet-item-5',
              navId: 'about_us_pages',
              name: itemLabels.get('about_us_pages') ?? 'About us',
              href: PATH.ABOUT_US,
            },
            {
              id: 'tablet-item-6',
              name: 'Privacy',
              href: PATH.PRIVACY_POLICY,
              passHref: true,
            },
            {
              id: 'tablet-item-7',
              name: 'Terms and conditions',
              href: PATH.TERMS_AND_CONDITIONS,
              passHref: true,
            },
            {
              id: 'tablet-item-8',
              name: itemLabels.get('contact_us_pages') ?? 'Contact us',
              href: PATH.CONTACT_US,
              passHref: true,
            },
          ],
        },
      ],
    },
    desktop: {
      columns: [
        {
          id: 'desktop-col-1',
          footerItemLinks: [
            {
              id: 'desktop-item-1',
              navId: 'job_search_pages',
              name: itemLabels.get('job_search_pages') ?? 'Job search',
              href: PATH.JOBS,
            },
            {
              id: 'desktop-item-2',
              navId: 'employers_pages',
              name: itemLabels.get('employers_pages') ?? 'Employers',
              href: PATH.EMPLOYERS,
            },
          ],
        },
        {
          id: 'desktop-col-2',
          footerItemLinks: [
            {
              id: 'desktop-item-3',
              navId: 'doctors_pages',
              name: itemLabels.get('doctors_pages') ?? 'Doctors',
              href: PATH.DOCTORS,
            },
          ],
        },
        {
          id: 'desktop-col-3',
          footerItemLinks: [
            {
              id: 'desktop-item-4',
              navId: 'articles_pages',
              name: itemLabels.get('articles_pages') ?? 'Articles',
              href: PATH.ARTICLES,
              passHref: true,
            },
            {
              id: 'desktop-item-5',
              navId: 'about_us_pages',
              name: itemLabels.get('about_us_pages') ?? 'About us',
              href: PATH.ABOUT_US,
            },
          ],
        },
        {
          id: 'desktop-col-4',
          footerItemLinks: [
            {
              id: 'desktop-item-6',
              name: 'Privacy',
              href: PATH.PRIVACY_POLICY,
              passHref: true,
            },
            {
              id: 'desktop-item-7',
              name: 'Terms and conditions',
              href: PATH.TERMS_AND_CONDITIONS,
              passHref: true,
            },
            {
              id: 'desktop-item-8',
              name: itemLabels.get('contact_us_pages') ?? 'Contact us',
              href: PATH.CONTACT_US,
              passHref: true,
            },
          ],
        },
      ],
    },
  };
}
