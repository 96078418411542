import { RefObject, useCallback, useEffect, useRef } from 'react';

export function useCloseOnOutsideClick(
  isOpen: boolean,
  onToggle: (boolean) => void,
): RefObject<HTMLDivElement> {
  const containerRef = useRef<HTMLDivElement>(null);

  // close if clicked outside
  const handleOutsideClick = useCallback(
    e => {
      if (isOpen) {
        if (containerRef.current?.contains(e.target)) {
          // inside click
          return;
        }
        // outside click
        onToggle(false);
      }
    },
    [isOpen],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [handleOutsideClick]);

  return containerRef;
}
